/* Common Imports */

import React from "react";
import PropTypes from "prop-types";

/* Global CSS Imports */

import "../styles/poppins/poppins.css";
import "../styles/index.css";

/* Theme Imports */

import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Theme from "../components/layout-components/Theme.js";

export function reportWebVitals(metric) {
  // console.log(metric); /* TODO: Collect metrics! */
}

export default function BeegruWebsiteMui(props) {
  const { Component, pageProps } = props;

  React.useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <React.Fragment>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </React.Fragment>
  );
}

BeegruWebsiteMui.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
};
